<script setup>
    import { ref, watch, defineEmits } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { context } from 'o365-modules';
    import { DataLookupControl, DataLookupList, DataLookupDropdown } from 'o365-datalookup';
    import OMatchMedia from 'o365.vue.components.MatchMedia.vue';
    import OrgunitsTree from 'o365.vue.components.OrgunitsTree.vue';
    import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';

    const props = defineProps({
        whereClause: {
            type: String
        }
    });

    const emits = defineEmits(['contextChanged', 'newContext']);

    const orgUnit = ref(context.name);
    const orgUnitId = ref(context.id);
    const dropdown = ref(null);
    
    const lookupRef = ref(null);
    const tabContent = ref(null);
    const lookupContainer = ref(null);
    const width = ref("400px");
    const id= crypto.randomUUID();

    let filterPartAllow = "[AllowAsContext] = 1",
        filterPartClosed = "[Closed] IS NULL ",
        filterPartOrgUnit = `IdPath LIKE '${context.idPath?.substring(0,context.idPath.substring(1).indexOf('/')+2)}%'`;
    
    let includeClosed = ref(false);
    let includeAllTopNodes = ref(false);

    let vWhereClause = filterPartAllow + " AND " + filterPartClosed + " AND " + filterPartOrgUnit;

    if (props.whereClause) {
        vWhereClause += " AND " + props.whereClause;
    }

    watch([includeAllTopNodes, includeClosed], () => {
        doFilter();
    });

    context.on('Change', ({ id, name, idPath }) => {
        filterPartOrgUnit = `IdPath LIKE '${idPath.substring(0, idPath.substring(1).indexOf('/') + 2)}%'`;
        orgUnit.value = name;
        orgUnitId.value = id;
    });
    
    const setContext = (selected) => {
        context.setContext(selected.ID).then(() => {
            orgUnit.value = context.name;
            orgUnitId.value = context.id;
            
            emits('newContext', context);
            emits('contextChanged', selected.ID);
        });

        dropdown.value?.dropdown.close()
    }

    const o_context_dsOrgUnits = getOrCreateDataObject({
        id: 'o_context_dsOrgUnits',
        viewName: 'sviw_System_OrgUnitsChooserToolbar', maxRecords: 1000,
        whereClause: vWhereClause,
        loadRecents: true,
        distinctRows: true,
        maxRecords: 100,
        fields: [
            {name: "ID", type: "number"},
            {name: "IdPath", type: "string" },
            {name: "OrgUnit", type: "string"},
            {name: "Closed", type: "date"},
            {name: "Name", type: "string" , sortOrder: 1, sortDirection: "asc"},
            {name: "Title", type: "string" , sortOrder: 2, sortDirection: "asc"},
            {name: "Domain_ID", type: "number"},
            {name: "Level", type: "number"},
            {name: "UnitType", type: "string"},
            {name: "NamePath", type: "string"},
            {name: "Parent", type: "string"},
            {name: "AccessIdPath", type: "string"}
        ]
    });

    // New ODataLookup doesn't have enough customization for us to put tabs into the desktop variant,
    // so this will be here until the mobile variant of ODataLookup has something that works like tree-view
    const lookupColumns = ref([{ field: "OrgUnit", width: 350 }, { field: "UnitType", width: 120 }, { field: "Parent", width: 200 }]);

    const lookupControl = new DataLookupControl({
        dataObject: o_context_dsOrgUnits,
        noClear: true,
        bind: (selected) => setContext(selected),
        noSearch: true,
        columns: lookupColumns.value,
        height: '400px',
        filterRow: true
    });

    const openDropdown = () => {
        const outputsize = () => {
            if (tabContent.value && lookupContainer.value.offsetWidth) {
                width.value = lookupContainer.value.offsetWidth + 'px';
            }
        }

        new ResizeObserver(outputsize).observe(tabContent.value);
    }

    const doFilter = () => {
        o_context_dsOrgUnits.recordSource.whereClause = getWhereClause();
        o_context_dsOrgUnits.load();

        whereClause.value = getWhereClause(true);
    }

    const getWhereClause = (pExcludeUserPart) => {
        let vFilter = filterPartAllow,
            includeClosedChb = includeClosed.value,
            includeAllTopNodesChb = includeAllTopNodes.value;

        if (includeClosedChb && !includeClosedChb.checked) {
            vFilter += " AND " + filterPartClosed
        } else {
             vFilter += " AND [Closed] IS NULL"
        }

        if (includeAllTopNodesChb == null) {
             vFilter +=  " AND " + filterPartOrgUnit
        }

        if (includeAllTopNodesChb && !includeAllTopNodesChb.checked) {
            vFilter +=  " AND " + filterPartOrgUnit
        }
        
        if (props.whereClause && !pExcludeUserPart) {
            vFilter += " AND "+props.whereClause;
        }

        return vFilter;
    }

    const whereClause = ref(getWhereClause(true));
</script>

<template>
    <OMediaQueryProvider>
        <OMatchMedia v-slot="{ isMobileOrTablet }">
            <template v-if="isMobileOrTablet">
                <ODataLookup class="form-select form-select-sm"
                    :data-object="o_context_dsOrgUnits"
                    :bind="(selected) => setContext(selected)">
                    <template #target="{ target }">
                        <span :ref="target" style="cursor:pointer;" class="d-flex text-truncate align-items-center">
                            <span class="text-truncate" style="min-width:0;">{{orgUnit}}</span>
                            <i class="bi bi-caret-down-fill ms-1"></i>
                        </span>
                    </template>

                    <template #toolbarActions>
                        <div class="d-flex gap-3 px-1">
                            <OToolbarAction :text="$t('Include all topnodes')" v-model="includeAllTopNodes"></OToolbarAction>
                            <OToolbarAction :text="$t('Include closed')" v-model="includeClosed"></OToolbarAction>
                        </div>
                    </template>

                    <OColumn field="OrgUnit" width="250"></OColumn>
                    <OColumn field="UnitType" width="150"></OColumn>
                    <OColumn field="Parent" width="150"></OColumn>
                </ODataLookup>
            </template>

            <template v-else>
                <DataLookupDropdown :dataLookupControl="lookupControl" @onopen="openDropdown" ref="dropdown">
                    <template #target="{ target }">
                        <span :ref="target" style="cursor:pointer;" class="d-flex align-items-center">
                            <span class="text-truncate">{{orgUnit }}</span>
                            <i class="bi bi-caret-down-fill ms-1"></i>
                        </span>
                    </template>
                    <template #listContainer>
                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <button class="nav-link active"  data-bs-toggle="tab" :data-bs-target="'#org-units-list'+id" type="button" role="tab">
                                    <small>{{$t('Org Units List')}}</small>
                                </button>
                                
                                <button class="nav-link" data-bs-toggle="tab" :data-bs-target="'#org-units-structure'+id" type="button" role="tab">
                                    <small>{{$t('Org Structure')}}</small>
                                </button>
                                
                                <div class="ms-auto mt-2">
                                    <div class="form-check form-check-inline ">
                                        <input class="form-check-input" :id="'include-all-topnodes'+id" type="checkbox" ref="includeAllTopNodes" @click="restrictToContext($event)">
                                        <label class="form-check-label" :for="'include-all-topnodes'+id">{{$t("Include all topnodes")}}</label>
                                    </div>
                                    <div class="form-check form-check-inline ">
                                        <input class="form-check-input" type="checkbox" :id="'include-closed'+id" ref="includeClosed" @click="doIncludeClosed($event)">
                                        <label class="form-check-label" :for="'include-closed'+id">{{$t("Include closed")}}</label>
                                    </div>
                                </div>
                            </div>
                        </nav>

                        <div class="tab-content" style="height:500px;" ref="tabContent">
                            <div class="tab-pane fade show active" :id="`org-units-list${id}`" role="tabpanel" tabindex="0">
                                <div class="d-flex" style="height:500px;" ref="lookupContainer">
                                    <DataLookupList :dataLookupControl="lookupControl" ref="lookupRef"></DataLookupList>
                                </div>
                            </div>
                            
                            <div class="tab-pane fade w-100" :id="`org-units-structure${id}`" role="tabpanel" tabindex="0">
                                <div class="d-flex flex-column" style="height:500px;" :style="{'width':width}">
                                    <OrgunitsTree @nodeClick="(selected) => setContext(selected.item)" :whereClause="whereClause"></OrgunitsTree>
                                </div>
                            </div>
                        </div>
                    </template>
                </DataLookupDropdown>
            </template>
        </OMatchMedia>
    </OMediaQueryProvider>
</template>